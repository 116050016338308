import { Maybe } from '@/types';

import { resolveContentAlignment } from './PatientReviews.helpers';

const imageSize = 7.875;
const gapSize = 2;

const calcVerticalSpacing = (hasButtons: boolean, hasSideImage: boolean) => {
	const spacingWithSideImage = hasButtons ? 3 + gapSize : imageSize;
	const desktopSpacing = hasSideImage ? spacingWithSideImage : 3;
	return { xs: '2rem', md: `${desktopSpacing}rem` };
};

export const getTitleContainerStyles = (contentAlign?: Maybe<string>) => ({
	alignItems: resolveContentAlignment(contentAlign, false),
	justifyContent: 'space-between',
	gap: '0.75rem',
});

export const buttonContainerStyles = {
	flexDirection: 'row',
	justifyContent: 'center',
	spacing: '0.25rem',
};

export const getContainerStyles = (
	contentAlign?: Maybe<string>,
	hasButtons?: Maybe<boolean>,
	hasSideImage?: Maybe<boolean>,
	disableTopPadding?: Maybe<boolean>,
	disableBottomPadding?: Maybe<boolean>
) => ({
	display: 'flex',
	flexDirection: 'column',
	boxSizing: 'border-box',
	alignItems: resolveContentAlignment(contentAlign, false),
	justifyContent: 'space-between',
	gap: `${gapSize}rem`,
	margin: '0 auto',
	paddingX: { xs: '1.25rem', md: '2%', lg: '5%' },
	...(disableTopPadding ? { paddingTop: 0 } : { paddingTop: { xs: '2rem', md: '3rem' } }),
	...(disableBottomPadding
		? { paddingBottom: 0 }
		: { paddingBottom: calcVerticalSpacing(!!hasButtons, !!hasSideImage) }),
});

export const imageBoxStyles = {
	position: 'relative',
	height: 0,
	width: '100%',
	overflow: 'visible',
};

export const sideImageStyles = (hasButtons?: Maybe<boolean>) => ({
	position: 'absolute',
	left: 0,
	top: hasButtons ? `${gapSize}rem` : 0,
	width: `${imageSize}rem`,
	height: `${imageSize}rem`,
});
